import { VApplication } from '@/application';
import { OrgComplianceDetailPageService } from '../../../services';

const MODULE_NAME = 'MyPoliciesDetailPage.machines.states.getPage';

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    'services.OrgComplianceDetailPageService'
  ) as OrgComplianceDetailPageService;
};

export const getPageContent = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'getPageContent';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.getPageContent();

      if (content) {
        return resolve({
          key: 'RENDER_PAGE',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'RENDER_PAGE_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'RENDER_PAGE_ERROR',
        value: err?.message,
      });
    }
  });
};
