import { VApplication } from '../../../../../application';
import { OrganizationPageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.updateProfile';

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    'services.OrganizationPageService'
  ) as OrganizationPageService;
};

export const updateProfile = async (context: any, event: any) => {
  const currentState = 'UPDATE_PROFILE';
  const method = 'updateProfile';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.updateProfile(event?.payload);
      if (content) {
        return resolve({
          key: 'UPDATE_PROFILE_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'UPDATE_PROFILE_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'UPDATE_PROFILE_FAILED',
        value: _msg,
      });
    }
  });
};
