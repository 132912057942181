import { VApplication } from '@/application';
import { logger as log } from '@common/logger';
import { ProfilePageService } from '@pages/Profile/services';

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync('services.ProfilePageService') as ProfilePageService;
};

export const updateProfile = async (context: any, event: any) => {
  const currentState = 'UPDATE_PROFILE';
  const method = 'updateProfile';

  return new Promise(async (resolve, reject) => {
    try {
      log.log('context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.updateProfile(event?.payload);
      if (content) {
        return resolve({
          key: 'UPDATE_PROFILE_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(`error in ${method}, msg: ${msg}`);
        return reject({
          key: 'UPDATE_PROFILE_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(`error in ${method}, msg: ${err?.message}`);
      return reject({
        key: 'UPDATE_PROFILE_FAILED',
        value: err?.message,
      });
    }
  });
};
