//@ts-nocheck
import React from 'react';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  BackspaceIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

import { toHeaderCase } from 'js-convert-case';
import { ToggleAction } from './ToggleAction';

import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import moment from 'moment-timezone';
import * as S from './styles';
import { constantCase } from 'change-case';
const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1);

const getFirstLetterOfEachWord = (text) =>
  text && constantCase(text)?.match(/\b\w/g).join('');

export const DomainTableRow = (props) => {
  const {
    id,
    rowData,
    vid,
    verified,
    status,
    item,
    batchNo,
    batchDate,
    batchExpiry,
    image,
    name,
    email,
    dateCreated,
    isSelected,
    selectUser,
    deleteUsers,
    coverage,
    premium,
    policyOption,
    onViewDetailClick,
    onRevokeClick,
    onRowClick,
    viewTitle,
    icon,
    packSize,
    batch,
    plantName,
    manufacturingDate,
    cases,
    bottles,
  } = props;
  console.log('date created =>', dateCreated);
  const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
  const statusVariant =
    status === 'active'
      ? 'success'
      : status === 'inactive'
      ? 'warning'
      : status === 'pending'
      ? 'purple'
      : status === 'revoked'
      ? 'danger'
      : 'primary';
  const onToggle = (key: string) => {
    console.log(`toggle in row`, key, email);
    if (key === 'view') {
      //onViewClick && onViewClick({ ...props });
    } else {
      onTogglePress && onTogglePress(key, email);
    }
  };
  const onUserChecked = (event: any, id: string) => {
    selectUser && selectUser(id);
    event.stopPropagation();
  };

  return (
    <tr className="border-bottom" onClick={() => onRowClick(rowData)}>
      <td>
        <FormCheck type="checkbox" className="dashboard-check">
          <FormCheck.Input
            id={`user-${id}`}
            checked={isSelected}
            onClick={(e) => onUserChecked(e, id)}
          />
          <FormCheck.Label htmlFor={`user-${id}`} />
        </FormCheck>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          {icon ? (
            <Image src={icon} className="avatar rounded-circle me-3" />
          ) : (
            <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
              <span>{name && getFirstLetterOfEachWord(name)}</span>
            </div>
          )}
          <div className="d-block">
            <span className="fw-bold">{name}</span>
          </div>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{packSize}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{batch}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{plantName}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{manufacturingDate}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{cases}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{bottles}</span>
        </Card.Link>
      </td>
      {/* <td>
        <span className="fw-normal d-flex align-items-center">
          <VerifiedIcon
            className={`icon icon-xxs text-${statusVariant} me-1`}
          />
          Email
        </span>
      </td> */}
      {/* <td>
        <span className={`fw-normal text-${statusVariant}`}>
          {capitalizeFirstLetter(status)}
        </span>
      </td> */}
      <td>
        <ToggleAction status={status} onTogglePress={onToggle} />
      </td>
    </tr>
  );
};

DomainTableRow.defaultProps = {
  viewTitle: 'View Certificate',
};
