import { VApplication } from '@/application';
import { LoggerModule } from '@vlinder-web/logger-module-react';
import { BulkImportPageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.importUser';
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync('services.BulkImportPageService') as BulkImportPageService;
};

export const createGroup = async (context: any, event: any) => {
  const currentState = 'CREATE_GROUP';
  const method = 'createGroup';

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.createGroup(event?.payload);
      if (content)
        return resolve({
          key: 'CREATE_GROUP_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: 'CREATE_GROUP_FAILED',
        value: err?.message,
      });
    }
  });
};
