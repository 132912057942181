import React, { useEffect } from 'react';

import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';

import { actions } from './actions';
import { MACHINE_CONFIG } from './config';
import { services } from './services';

export const orgComplianceListPageContext = React.createContext({});
export let orgComplianceListPageMachine: any;

const MODULE_NAME = 'machines.pageContent';

export const useSMOrgComplianceListPageGetContent = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  orgComplianceListPageMachine = createMachine(_machineConfig, {
    services,
    actions,
  });
  const [state, send] = useMachine(orgComplianceListPageMachine);

  useEffect(() => {
    send({ type: 'START' });
  }, []);

  return {
    orgComplianceListPageMachine,
    orgComplianceListPageState: state,
    orgComplianceListPageSend: send,
  };
};
