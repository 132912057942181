import { VApplication } from '@/application';
import { SuppChainTempOverViewPageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.getPage';

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    'services.SuppChainTempOverViewPageService'
  ) as SuppChainTempOverViewPageService;
};

export const updateTraceStep = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'updateTraceStep';

  return new Promise(async (resolve, reject) => {
    try {
      const _getArgs = () => {
        let _args: any = {};
        if (event?.type === 'START') {
          _args = context['GET_PAGE_CONTENT'];
        }
        if (event?.type === 'TRIGGER_REFRESH') {
          _args = {
            offset: event?.offset,
            size: event?.size,
            where: event?.where ?? [],
          };
        }
        console.log('updateTraceStep args =>', _args);
        return _args;
      };

      const srv = getSrv();
      const content = await srv.updateTraceStep(event?.payload);

      if (content) {
        return resolve({
          key: 'UPDATE_TRACE_STEP_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'UPDATE_TRACE_STEP_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'UPDATE_TRACE_STEP_ERROR',
        value: _msg,
      });
    }
  });
};
